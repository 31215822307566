import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
const headingAccentStyles = {
  color: "#663399",
  textDecoration: "none",
  "&:hover": {
    color: "red",
  },
};

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 100,
  marginBottom: 0,
  lineHeight: 0,
  display: "flex",
  alignItems: "center",
};

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>It's Tezza!</title>
      <div>
        <h1 style={headingStyles}>
          Here's what I've been listening to on Spotify recently
          <br />
          <span style={headingAccentStyles}>— </span>
          <a
            href="https://open.spotify.com/artist/2KEqzdPS7M5YwGmiuPTdr5?si=PL2Pvh0JQRGJek5szNpZTg&dl_branch=1"
            style={headingAccentStyles}
          >
            Rina Sawayama
          </a>
        </h1>
        <iframe
          title="spotify playlist"
          src="https://open.spotify.com/embed/playlist/2kc1OUBnfoGtqqOHuy6cH3?theme=0"
          width="320px"
          height="600px"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
      <span style={descriptionStyle}>
        <a href="https://github.com/tezf" style={headingAccentStyles}>
          My Github
        </a>
      </span>
    </main>
  );
};

export default IndexPage;
